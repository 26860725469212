import React from "react";
import { Link } from "react-router-dom";

/**
 * Page not found (404) componenet
 */
const NotFound = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <h2>Síða finnst ekki :/</h2>
      <p>
        <Link to="/">Aftur á forsíðu</Link>
      </p>
    </div>
  );
};

export default NotFound;
