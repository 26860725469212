//@ts-nocheck
import { ReactElement, useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection";
import "./ShowCases.scss";
import { Link } from "react-router-dom";

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/b5otehmyi9gl/environments/master?access_token=z_tkJTijR5q1kqhDwmy4DppZrXa3jhpHqj0Zw71Lnvs",
  cache: new InMemoryCache(),
});

// shorten string to 100 characters with ellipsis
const ellipsis = function (str: string, maxLength: number) {
  if (str.length > maxLength) {
    return `${str.substr(0, maxLength - 3)} ...`;
  }
  return str;
};

interface CaseStudy {
  items: [
    {
      fields: {
        thumbnail: {};
        title: string;
        customerName: string;
        description: { content: [{ content: [{ value: string }] }] };
      };
    }
  ];
}

const formatImage = (url, width) => {
  return url.replace("downloads", "images") + `?w=${width}`;
};

export default function ShowCases(): ReactElement {
  const [data, setData] = useState();

  useEffect(() => {
    /* getContentCategory("caseStudy").then((rsp) => {
      setCaseStudies(rsp.data);
    }); */

    client
      .query({
        query: gql`
          query {
            verkinCollection {
              items {
                title
                subtitle

                verkinCollection(limit: 50) {
                  items {
                    sys {
                      id
                    }
                    title
                    description {
                      json
                    }
                    customerName
                    thumbnail {
                      url
                    }
                  }
                }
              }
            }
          }
        `,
      })
      .then((res) => {
        setData(res.data.verkinCollection.items[0]);
      });
  }, []);

  return (
    <>
      <HeroSection id="showCases" className="topLevelContainer">
        <>
          <div className="mainTitle">{data && data.title}</div>
          <div className="subTitle">{data && data.subtitle}</div>

          <div className="text-left mt50">
            <img src="/images/long-arrow.png" />
          </div>

          <div>
            {data &&
              data.verkinCollection.items.map((caseStudy, key) => {
                const thumbnail = formatImage(caseStudy.thumbnail.url, 500);

                return (
                  <div className="caseStudyPreview" key={key}>
                    <div
                      className="caseStudyPreviewImage"
                      style={{
                        backgroundImage: `url(${thumbnail})`,
                      }}
                    ></div>
                    <div className="caseStudyPreviewInfo">
                      <div className="caseStudyPreviewTitle">
                        {caseStudy.title}
                      </div>
                      <div className="caseStudyPreviewCustomer">
                        {caseStudy.customerName}
                      </div>
                      <div className="caseStudyPreviewDescription">
                        {ellipsis(
                          caseStudy.description.json.content[0].content[0]
                            .value,
                          300
                        )}
                      </div>

                      <div style={{ textAlign: "right", marginTop: 30 }}>
                        <Link
                          to={`/verk/${caseStudy.sys.id}`}
                          className="seeCaseStudy underlineBorder"
                        >
                          SKOÐA VERKEFNI
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      </HeroSection>
    </>
  );
}
