import React, { useState } from "react";
import { Layout } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";

const { Header: AntHeader } = Layout;

export default function Header() {
  const [menu, setMenu] = useState(false);
  const [darkHeader, setDarkHeader] = useState(true);

  const location = useLocation();

  React.useEffect(() => {
    const pathname = location.pathname;

    if (pathname.includes("teymid")) {
      setDarkHeader(false);
    } else {
      setDarkHeader(true);
    }
  }, [location]);

  return (
    <>
      <AntHeader className={`header ${!darkHeader ? "lightHeader" : ""}`}>
        <Link
          onClick={() => {
            setMenu(false);
          }}
          to="/"
        >
          <div className="logo">
            {!darkHeader ? (
              <SVG
                id="header_logo_light"
                style={{ height: "44px" }}
                baseURL="/"
                description="Logo"
                preProcessor={(code) => {
                  return code.replace(/fill:#FFFFFF/g, "fill:#000");
                }}
                src={"/images/kiwi_logo.svg"}
                title="Logo"
                key="header_logo_light"
                uniqueHash="header_logo_light"
                uniquifyIDs={false}
                fill="#000"
              />
            ) : (
              <SVG
                id="header_logo_dark"
                style={{ height: "44px" }}
                baseURL="/"
                description="Logo"
                src={"/images/kiwi_logo.svg"}
                title="Logo"
                key="header_logo_dark"
                uniqueHash="header_logo_dark"
                uniquifyIDs={false}
                fill="#fff"
              />
            )}
          </div>
        </Link>

        <MenuOutlined
          className="hamburgerIcon"
          style={!darkHeader ? { color: "#000" } : {}}
          onClick={() => {
            setMenu(!menu);
          }}
        />
      </AntHeader>

      <div id="kiwiMenu" className={menu ? "" : "hidden"}>
        <div className="menuItems">
          <Link
            onClick={() => {
              setMenu(false);
            }}
            to="/verkin"
          >
            VERKIN
          </Link>{" "}
          <Link
            onClick={() => {
              setMenu(false);
            }}
            to="/thjonustan"
          >
            ÞJÓNUSTAN
          </Link>
          {/* <Link
            onClick={() => {
              setMenu(false);
            }}
            to="/voxtur"
          >
            VÖXTUR
          </Link> */}
          <Link
            onClick={() => {
              setMenu(false);
            }}
            to="/teymid"
          >
            TEYMIÐ
          </Link>
          {/* <Link
            onClick={() => {
              setMenu(false);
            }}
            to="/podcast"
          >
            PODCAST STUDIO
          </Link> */}
          {/* <Link
            onClick={() => {
              setMenu(false);
            }}
            to="/blog"
          >
            BLOG
          </Link> */}
        </div>
      </div>
    </>
  );
}
