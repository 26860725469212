//@ts-nocheck
import React, { ReactElement, useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection";
import getContentCategory from "../../utils/getContentCategory";
import RichTextRender from "../../components/RichTextRender/RichTextRender";
import { getFieldMediaById } from "../../utils/utils";
import Kubbur from "../../components/Kubbur/Kubbur";

import "./Service.scss";

interface Props {}

export default function Service({}: Props): ReactElement {
  const [data, setData] = useState();

  useEffect(() => {
    getContentCategory("thjonustan").then((rsp) => {
      setData(rsp.data);
    });
  }, []);

  return (
    <div id="thjonustan" className="topLevelContainer">
      {/* Intro section*/}
      <HeroSection
        className="introSection"
        vheight={80}
        innerContainerStyle={{ maxWidth: 650, alignSelf: "flex-start" }}
      >
        <>
          {data && <div className="title">{data.items[0].fields.titill}</div>}
          {data && (
            <div className="subTitle">{data.items[0].fields.undirtitill}</div>
          )}
          {data && (
            <div className="intro">{data.items[0].fields.inngangstexti}</div>
          )}

          <div className="text-left mt50">
            {data && <img src="/images/long-arrow.png" />}
          </div>
        </>
      </HeroSection>

      {/* Kubba section */}
      <div id="kubbar">
        {data &&
          data.items[0].fields.kubbur.map((kubbur, key) => {
            const fields = data.includes.Entry.find(
              (entry) => entry.sys.id === kubbur.sys.id
            ).fields;

            return <Kubbur fields={fields} data={data} key={key}></Kubbur>;
          })}
      </div>
    </div>
  );
}
