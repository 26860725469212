//@ts-nocheck
import React, { ReactElement } from "react";
import isTouchDevice from "is-touch-device";
import "./HeroSection.scss";

const is_touch = isTouchDevice();

interface Props {
  className?: string;
  id?: string;
  overlayStyle?: object;
  children: ReactElement;
  innerContainerStyle?: object;
  videoSrc?: string;
  mobileVideoSrc?: string;
  vheight?: number;
}

export default function HeroSection(props: Props): ReactElement {
  return (
    <section
      id={props.id}
      className={`HeroSection ${props.className || ""}`}
      style={props.vheight ? { minHeight: props.vheight + "vh" } : {}}
    >
      {props.videoSrc &&
        (is_touch ? (
          <video
            className="video_small"
            muted={true}
            autoPlay={true}
            src={props.mobileVideoSrc || props.videoSrc}
            loop={true}
          ></video>
        ) : (
          <video
            className="video_big"
            muted={true}
            autoPlay={true}
            src={props.videoSrc}
            loop={true}
          ></video>
        ))}
      {!!props.overlayStyle && (
        <div style={props.overlayStyle} className="heroSectionOverlay"></div>
      )}
      <div className="innerContainer" style={props.innerContainerStyle}>
        <div className="mainContent">{props.children}</div>
      </div>
    </section>
  );
}
