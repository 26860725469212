import React, { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import RichTextRender from "../components/RichTextRender/RichTextRender";
import getContentCategory, {
  getSingleAsset,
  getImage,
} from "../utils/getContentCategory";
import { getFieldMedia } from "../utils/utils";
import ItemsCarousel from "react-items-carousel";
import { Link } from "react-router-dom";
import "./Frontpage.scss";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/b5otehmyi9gl/environments/master?access_token=z_tkJTijR5q1kqhDwmy4DppZrXa3jhpHqj0Zw71Lnvs",
  cache: new InMemoryCache(),
});

export default function Frontpage() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const [playButtonActive, setPlayButtonActive] = useState(false);
  const [caseStudies, setCaseStudies] = useState([]);
  const [showCases, setShowCases] = useState([]);
  const [frontpage, setFrontpage] = useState();
  const [companyLogos, setCompanyLogos] = useState([]);

  useEffect(() => {
    client
      .query({
        query: gql`
          query {
            caseCollection {
              items {
                title
                logo {
                  url
                }
                title
              }
            }

            companyLogosCollection {
              items {
                image {
                  url
                }
              }
            }

            caseStudyCollection {
              items {
                sys {
                  id
                }
                title
                customerName
                thumbnail {
                  url
                }
                description {
                  json
                }
              }
            }

            frontpageMainTitleCollection {
              items {
                sectionTwoTitle {
                  json
                }
                sectionTwoBody {
                  json
                }
                showreel
                bakgrunnsmyndband {
                  url
                }
                mobileBackground {
                  url
                }
                texti {
                  json
                }
                footerImage {
                  url
                }
              }
            }
          }
        `,
      })
      .then((res) => {
        setCaseStudies(res.data.caseCollection.items);
        setShowCases(res.data.caseStudyCollection.items);
        setFrontpage(res.data.frontpageMainTitleCollection.items[0]);
        setCompanyLogos(res.data.companyLogosCollection.items);
      });
  }, []);

  return (
    <>
      {frontpage && (
        <>
          <HeroSection
            vheight={100}
            id="frontMain"
            videoSrc={
              window.innerWidth > 499 ? frontpage.bakgrunnsmyndband.url : null
            }
            overlayStyle={{
              backgroundColor: "#000",
              opacity: window.innerWidth > 499 ? 0.5 : 1,

              backgroundImage:
                window.innerWidth > 499
                  ? 0.5
                  : "url(" + frontpage.mobileBackground.url + ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <>
              <h1 className="frontpageTitle">
                <RichTextRender
                  style={{ color: "#fff" }}
                  className="richTextContent mainTitle"
                  content={frontpage.texti.json}
                ></RichTextRender>
              </h1>
              <img
                onClick={() => {
                  setPlayButtonActive(!playButtonActive);
                }}
                className={playButtonActive ? "active" : ""}
                alt="Spila takki"
                style={{ width: 90, height: 90, cursor: "pointer" }}
                src="/images/play-icon.gif"
              />
            </>
          </HeroSection>

          <HeroSection
            className="sectionTwo"
            overlayStyle={{ backgroundColor: "black" }}
            vheight={60}
          >
            <RichTextRender
              className="richTextContent whiteFont sectionTwoTitle"
              content={frontpage.sectionTwoTitle.json}
            ></RichTextRender>
            <div>
              <RichTextRender
                className="richTextContent whiteFont sectionTwoBody"
                content={frontpage.sectionTwoBody.json}
              ></RichTextRender>
            </div>
          </HeroSection>

          <HeroSection className="imageCarousel">
            {showCases && (
              <>
                <ItemsCarousel
                  infiniteLoop
                  gutter={0}
                  numberOfCards={window.innerHeight < 768 ? 2 : 3}
                  activeItemIndex={activeItemIndex}
                  requestToChangeActive={setActiveItemIndex}
                  rightChevron={
                    <img
                      style={{ marginRight: 20 }}
                      src="/images/right-arrow.svg"
                      alt="Left arrow"
                    />
                  }
                  leftChevron={
                    <img
                      style={{ marginLeft: 20 }}
                      src="/images/left-arrow.svg"
                      alt="Left arrow"
                    />
                  }
                  chevronWidth={20}
                  children={showCases.map((showcase, i) => {
                    return (
                      <div
                        key={i}
                        className="carouselImage"
                        style={{
                          background: `url(${showcase.thumbnail.url.replace(
                            "downloads",
                            "images"
                          )}?w=750)`,
                        }}
                      >
                        <div className="innerDiv">
                          <div className="jobName">{showcase.customerName}</div>
                          <div className="jobTitle">{showcase.title}</div>
                          <div className="jobDescription">
                            <RichTextRender
                              className="richTextContent whiteFont sectionTwoBody"
                              content={showcase.description.json}
                              ellipsis={{
                                rows: 4,
                                expandable: false,
                              }}
                            ></RichTextRender>
                          </div>
                          <Link
                            to={`/verk/${showcase.sys.id}`}
                            className="seeMore"
                          >
                            Skoða verkefni
                          </Link>
                          <div className="seeMore"></div>
                        </div>
                      </div>
                    );
                  })}
                />

                <Link to="/verkin" className="seeAll underlineBorder">
                  Sjá fleiri verk
                </Link>
              </>
            )}
          </HeroSection>

          <HeroSection
            overlayStyle={{ backgroundColor: "#000", color: "#fff" }}
            innerContainerStyle={{ backgroundColor: "#000", color: "#fff" }}
          >
            <div className="experienceSection">
              <div className="leftSection">
                <div className="experienceLabel">VIÐSKIPTAVINIR</div>
                <div className="experienceTitle">Í góðum félagsskap</div>

                {/*   <div>
                  <span className="experienceSubtitle">
                    Einstök þjónusta í áskrift
                  </span>

                  <span>
                    <a
                      href={`/verkin`}
                      className="seeCaseStudy underlineBorder"
                    >
                      Sjá case study
                    </a>
                  </span>
                </div> */}
              </div>

              <div className="rightSection">
                <div id="companies">
                  {companyLogos.map((logo, i) => {
                    return (
                      <div key={i} className="company">
                        <img
                          src={logo.image.url.replace("downloads", "images")}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </HeroSection>

          <HeroSection
            overlayStyle={{
              backgroundImage: `url(${frontpage.footerImage.url})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            vheight={30}
          ></HeroSection>
        </>
      )}

      {playButtonActive && frontpage && (
        <div className="fullscreen">
          <div
            className="closeFullScreen"
            onClick={() => {
              setPlayButtonActive(false);
            }}
          >
            X
          </div>
          <div className="video">
            <div className="wrapper">
              <iframe
                src={frontpage.showreel}
                width="500"
                height="281"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen; picture-in-picture"
                title="Show reel KIWI"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
