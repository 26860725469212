//@ts-nocheck
import { ReactElement, useEffect, useState } from "react";
import getContentCategory from "../../utils/getContentCategory";
import copy from "copy-to-clipboard";
import { message } from "antd";
import { getSingleEntry, getImage } from "../../utils/getContentCategory";
import RichTextRender from "../../components/RichTextRender/RichTextRender";
import "./Staff.scss";

interface Employee {
  items: [
    {
      fields: {
        image: {};
        name: string;
        email: string;
        jobTitle: string;
        shortDescription: string;
        description: { content: [{ content: [{ value: string }] }] };
      };
    }
  ];
}

export default function Staff(): ReactElement {
  const [data, setData] = useState();
  const [employees, setEmployees] = useState();

  useEffect(() => {
    getContentCategory("employeePage").then((rsp) => {
      const data = rsp.data;

      setData(data);

      getEmployees(data.items[0].fields.employees);
    });
  }, []);

  const getEmployees = (items) => {
    const promises = items.map((item) => {
      return getSingleEntry(item.sys.id);
    });

    Promise.all(promises).then((rsp) => {
      const d = rsp.map((e) => {
        const employee = e.data.fields;
        return {
          email: employee.email,
          jobTitle: employee.jobTitle,
          name: employee.name,
          shortDescription: employee.shortDescription,
          image: getImage(employee.image.sys.id, 500),
        };
      });

      Promise.all(d.map((r) => r.image)).then((rsp, i) => {
        const staff = rsp.map((imageUrl, index) => {
          return {
            ...d[index],
            image: imageUrl,
          };
        });

        setEmployees(staff);
      });
    });
  };

  return (
    <div id="staff">
      <>
        <div className="whiteSection">
          <div className="textSection">
            <h1 id="staffTitle">{data && data.items[0].fields.title}</h1>

            {data && (
              <RichTextRender
                className="richTextContent"
                content={data.items[0].fields.introText}
              ></RichTextRender>
            )}
          </div>

          <div id="darkEmployees">
            {employees &&
              employees.map((employee, key) => {
                return (
                  <div className="darkEmployee" key={key}>
                    <img src={employee.image} alt={employee.name} />
                  </div>
                );
              })}
          </div>
        </div>

        <div id="employees">
          {employees &&
            employees.map((employee, key) => {
              const { name, email, jobTitle, shortDescription, image } =
                employee;

              return (
                <div
                  className="employee"
                  style={{ backgroundImage: `url(${image})` }}
                  key={key}
                  onClick={() => {
                    copy(email);
                    message.success(
                      "Netfangið " + email + " hefur verið afritað."
                    );
                  }}
                >
                  <div className="employeeInfo">
                    <div className="employeeName">{name}</div>
                    <div className="employeeTitle">{jobTitle}</div>
                    <div className="employeeEmail">{email}</div>
                    <div className="employeeDescription">
                      {shortDescription}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </>
    </div>
  );
}
