//@ts-nocheck
import React, { ReactElement, useState, useEffect } from "react";
import Kubbur from "../../components/Kubbur/Kubbur";
import getContentCategory from "../../utils/getContentCategory";
import "./PodcastStudio.scss";

interface Props {}

export default function PodcastStudio({}: Props): ReactElement {
  const [data, setData] = useState();

  useEffect(() => {
    getContentCategory("podcastStudio").then((rsp) => {
      setData(rsp.data);
    });
  }, []);

  return (
    <div id="podcastStudio">
      <>
        {data &&
          data.items[0].fields.kubbar.map((kubbur, key) => {
            const fields = data.includes.Entry.find(
              (entry) => entry.sys.id === kubbur.sys.id
            ).fields;

            return <Kubbur fields={fields} data={data} key={key}></Kubbur>;
          })}

        {/* <div className="leftSection">
          <div className="textSection">
            <h1 className="textTitle">Podcast Studio</h1>
            <p className="textIntro">
              Við vinnum að því daglega að vera leiðandi í þjónustugæðum á sviði
              stafrænnar markaðssetningar. Við leggjum mikið upp úr því að veita
              öllum viðskiptavinum okkar einstaka þjónustuupplifun sem fer fram
              úr þeirra væntingum.
            </p>
          </div>
        </div> */}
      </>

      <div id="shows">
        {data &&
          data.items[0].fields.podcastShows.map((show, key) => {
            const fields = data.includes.Entry.find(
              (entry) => entry.sys.id === show.sys.id
            ).fields;

            const image = data.includes.Asset.find(
              (asset) => asset.sys.id === fields.image.sys.id
            );

            return (
              <div className="show" key={key}>
                <div
                  className="showImage"
                  style={{ backgroundImage: `url(${image.fields.file.url})` }}
                ></div>
                <div className="showTitle">{fields.name}</div>
                <div className="showNames">{fields.names}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
