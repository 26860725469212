//@ts-nocheck
import React, { ReactElement, useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection";
import "./Growth.scss";
import getContentCategory from "../../utils/getContentCategory";
import { getFieldMediaById } from "../../utils/utils";
import { Link } from "react-router-dom";

export default function Growth({}: Props): ReactElement {
  const [cases, setCases] = useState();

  useEffect(() => {
    getContentCategory("case").then((rsp) => {
      setCases(rsp.data);
    });
  }, []);

  return (
    <div id="growth">
      <div id="top">
        <div className="gheader">
          <h1 className="title">Vöxtur</h1>
          <div className="description">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem error
            deleniti, dolorem eaque dolore amet nobis consectetur deserunt
            maiores provident impedit quo unde tempora dolores laboriosam
            consequuntur libero nam culpa?
          </div>
        </div>

        <HeroSection>
          <div className="explanation">
            <div className="leftSection">
              <div className="question">
                <div className="label">Fyrir hverja er</div>
                <div className="title">Áskrift?</div>
              </div>

              <div className="question">
                <div className="label">Hvernig virkar</div>
                <div className="title">Áskrift?</div>
              </div>

              <div className="question">
                <div className="label">Þjónustuleiðir í</div>
                <div className="title">Áskrift?</div>
              </div>
            </div>

            <div className="rightSection">
              <p>
                Áskrift er fyrir metnaðargjörn fyrirtæki af öllum stærðum og
                gerðum
              </p>

              <p>
                B2C og B2B vörumerki Vefverslanir Fyrirtæki sem vilja vaxa
                Fyrirtæki sem vilja þróast í takt við tímann
              </p>

              <p>
                Þú borgar þú fast mánaðargjald fyrir vel skilgreinda þjónustu
                sem er aðsniðin þínum þörfum til að ná þeim árangri sem þú óskar
                eftir.
              </p>

              <p>
                Áskrift er fyrir metnaðargjörn fyrirtæki af öllum stærðum og
                gerðum
              </p>

              <p>
                B2C og B2B vörumerki Vefverslanir Fyrirtæki sem vilja vaxa
                Fyrirtæki sem vilja þróast í takt við tímann
              </p>

              <p>
                Þú borgar þú fast mánaðargjald fyrir vel skilgreinda þjónustu
                sem er aðsniðin þínum þörfum til að ná þeim árangri sem þú óskar
                eftir.
              </p>
            </div>
          </div>
        </HeroSection>
      </div>

      <HeroSection
        className="happyCustomers"
        innerContainerStyle={{ background: "#fff", color: "#000" }}
      >
        <h1>Ánægð fyrirtæki sem hafa nýtt sér vöxtinn</h1>

        <div id="customerList">
          {cases &&
            cases.items.map((item, key) => {
              return (
                <div className="customer" key={key}>
                  <img
                    src={getFieldMediaById(item.fields.logo.sys.id, cases).url}
                    alt=""
                  />
                  <div className="caseName">{item.fields.title}</div>
                  <Link
                    className="seeCaseStudy underlineBorder"
                    to={`casestudy/${item.sys.id}`}
                  >
                    Skoða verkefni
                  </Link>
                </div>
              );
            })}
        </div>
      </HeroSection>
    </div>
  );
}
