//@ts-nocheck
import React, { ReactElement } from "react";
import RichTextRender from "../../components/RichTextRender/RichTextRender";
import { getFieldMediaById } from "../../utils/utils";

interface Props {
  data: object;
  fields: {
    dark: boolean;
    imageOnTheRight: boolean;
    mynd: object;
    titill: string;
    texti: string;
    imageAsSectionBackground: boolean;
  };
}

export default function Kubbur(props: Props): ReactElement {
  const {
    dark,
    imageOnTheRight,
    mynd,
    texti,
    titill,
    imageAsSectionBackground,
  } = props.fields;
  const imageUrl = mynd
    ? getFieldMediaById(mynd.sys.id, props.data).url.replace(
        "downloads",
        "images"
      ) + "?w=1000"
    : null;

  return (
    <div
      className={`kubbur ${dark ? "dark" : "light"} ${
        imageOnTheRight ? "imageOnTheRight" : "imageOnTheLeft"
      }`}
      style={
        imageAsSectionBackground ? { backgroundImage: `url(${imageUrl})` } : {}
      }
    >
      <div className="kubburInner">
        <div
          className={`side ${!imageAsSectionBackground ? "bgImage" : ""}`}
          style={
            !imageAsSectionBackground
              ? { backgroundImage: `url(${imageUrl})` }
              : {}
          }
        ></div>

        <div className="side">
          <div className="title">{titill}</div>
          <RichTextRender
            className="content"
            content={texti}
            includes={props.data.includes}
          ></RichTextRender>
        </div>
      </div>
    </div>
  );
}
