import React, { Component } from "react";
import { Layout } from "antd";

const { Footer: AntFooter } = Layout;

export default class Footer extends Component {
  render() {
    return (
      <AntFooter
        style={{ background: "#000", color: "#fff" }}
        className="text-center"
      >
        {this.props.children}
      </AntFooter>
    );
  }
}
