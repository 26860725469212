import React, { useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { NotFound } from "./pages";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";

/* Pages */
import ShowCases from "./pages/ShowCases/ShowCases";
import Staff from "./pages/Staff/Staff";
import Service from "./pages/Service/Service";
import Frontpage from "./pages/Frontpage";
import Growth from "./pages/Growth/Growth";

import Header from "./components/Header";
import Footer from "./components/Footer";
import "antd/dist/antd.css";
import PodcastStudio from "./pages/PodcastStudio/PodcastStudio";
import ShowCase from "./pages/ShowCase/ShowCase";
import CaseStudy from "./pages/CaseStudy/CaseStudy";
import Axios from "axios";
import ContactUs from "./components/ContactUs";
const { TextArea } = Input;

const App = (props) => {
  const location = useLocation();
  const [pageClass, setPageClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + `=` + encodeURIComponent(data[key])
      )
      .join(`&`);
  }

  React.useEffect(() => {
    const pathname = location.pathname;
    setPageClass(pathname.replaceAll("/", ""));

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Header></Header>

      <div className={pageClass} style={{ minHeight: "calc(100vh - 135px)" }}>
        <Switch>
          <Route exact path="/" component={Frontpage} />
          <Route path="/verk/:uid" component={ShowCase} />
          <Route exact path="/verkin" component={ShowCases} />
          <Route exact path="/teymid" component={Staff} />
          <Route exact path="/thjonustan" component={Service} />
          <Route exact path="/podcast" component={PodcastStudio} />
          <Route exact path="/voxtur" component={Growth} />
          <Route exact path="/casestudy/:uid" component={CaseStudy} />
          <Route exact path="/hafa-samband" component={ContactUs} />
          <Route component={NotFound} />
        </Switch>
        <div className="stayInTouchFooter">
          <div style={{ maxWidth: 500, width: "100%" }}>
            <Form
              name="cf"
              method="post"
              onFinish={(values) => {
                if (values[`bot-field`] === undefined) {
                  delete values[`bot-field`];
                }

                setLoading(true);

                Axios.post(
                  "/",
                  encode({
                    "form-name": "contact",
                    ...values,
                  }),
                  {
                    headers: {
                      "Content-Type": `application/x-www-form-urlencoded`,
                    },
                  }
                )
                  .then(() => {
                    message.success("Tókst, skilaboðin hafa verið send 🥳");
                    setLoading(false);
                    setDisabled(true);
                  })
                  .catch((error) => {
                    message.error("Eitthvað fór úrskeiðis :/");
                    setLoading(false);
                  });
              }}
              layout="vertical"
            >
              {/* This is the hidden field that the netlify-honeypot uses. */}
              <input type="hidden" name="form-name" value="contact" />
              <Form.Item
                label="Don't fill this out"
                className={`hidden`}
                style={{ display: `none` }}
                name="bot-field"
              >
                <Input type={`hidden`} />
              </Form.Item>

              <Form.Item
                label="Nafn"
                rules={[
                  {
                    required: true,
                    message: `Vinsamlegast fylltu út nafnið þitt.`,
                  },
                ]}
                name="name"
              >
                <Input
                  placeholder="Nafn"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                label="Email"
                rules={[
                  {
                    required: true,
                    type: `email`,
                    message: `Vinsamlegast fylltu út netfang.`,
                  },
                ]}
                name="email"
              >
                <Input
                  placeholder="Netfangið þitt"
                  prefix={<MailOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item label="Símanúmer" name="phone">
                <Input
                  placeholder="Símanúmerið þitt"
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                />
              </Form.Item>

              <Form.Item
                label="Skilaboð"
                rules={[
                  {
                    required: true,
                    message: `Vinsamlegast fylltu út skilaboðin.`,
                  },
                ]}
                name="message"
              >
                <TextArea placeholder="Skilaboð" rows={5} />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                  disabled={disabled}
                  loading={loading}
                  style={{ background: "#000", border: "none" }}
                >
                  Senda
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>

      <Footer>
        <div className="kiwiFooter">
          <div>
            <span>KIWI</span> <span>DVERGSHÖFÐI 2, 2. HÆÐ</span>
            <span>110 REYKJAVÍK</span>
            <span>693 9282</span>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default App;
