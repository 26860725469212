//@ts-nocheck
import React, { ReactElement, useState } from "react";
import { getSingleEntry, getSingleAsset } from "../../utils/getContentCategory";
import RichTextRender from "../../components/RichTextRender/RichTextRender";
import ReactPlayer from "react-player";

import { Link, useLocation } from "react-router-dom";
import "./ShowCase.scss";

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/b5otehmyi9gl/environments/master?access_token=z_tkJTijR5q1kqhDwmy4DppZrXa3jhpHqj0Zw71Lnvs",
  cache: new InMemoryCache(),
});

interface Props {}

const ellipsis = function (str: string, maxLength: number) {
  if (str.length > maxLength) {
    return `${str.substr(0, maxLength - 3)} ...`;
  }
  return str;
};

const formatImage = (url, width) => {
  return url.replace("downloads", "images") + `?w=${width}`;
};

export default function ShowCase({}: Props): ReactElement {
  const [data, setData] = useState();
  const location = useLocation();
  const [nextCase, setNextCase] = useState();

  React.useEffect(() => {
    const pathname = location.pathname;

    const entryId = pathname.replaceAll("/verk/", "");

    client
      .query({
        query: gql`
        query {
          caseStudy(id: "${entryId}") {
            title
            customerName
            description{
              json
            }
            mainImage {
              url
            }
            
            vimeoVideos
            mobileImagesCollection{
              items {
                url
              }
            }
            heroImagesCollection{
              items{
                url
              }
            }
          }

          caseStudyCollection{
            items{
              customerName
              sys{
                id
              }
              thumbnail{
                url
              }
              title
              description{
                json
              }
            }
          }
        }
      `,
      })
      .then((res) => {
        console.log(res);
        setData(res.data.caseStudy);

        const currentCaseIndex = res.data.caseStudyCollection.items.findIndex(
          (item) => {
            return item.sys.id === entryId;
          }
        );
        const nextCaseIndex = currentCaseIndex + 1;
        const nextCase = res.data.caseStudyCollection.items[nextCaseIndex];
        if (nextCase) {
          setNextCase(nextCase);
        } else {
          setNextCase(res.data.caseStudyCollection.items[0]);
        }
      });

    /* .replace("downloads", "images") + "?w=1200" */
  }, [location]);

  /*   customerName,
    description,
    goal,
    kiwiBullets,
    mainImage,
    thumbnail,
    title, */

  return (
    <div id="showCase">
      {data && (
        <>
          <img
            style={{ width: "100%" }}
            src={formatImage(data.mainImage.url, 1600)}
            alt="Aðal myndin"
          />

          <div className="infoSection">
            <div className="leftInfo">
              <div>
                <div className="label">Verkefnið</div>
                <div className="jobName">{data.title}</div>
              </div>

              <div>
                <div className="label">Viðskiptavinur</div>
                <div className="companyName">{data.customerName}</div>
              </div>

              {/* <div className="kiwiBullets">
                <div className="label">Aðkoma Kiwi</div>
                <RichTextRender
                  style={{ color: "#fff" }}
                  className="richTextContent bulletContent"
                  content={data.kiwiBullets.json}
                ></RichTextRender>
              </div> */}
            </div>
            <div className="rightInfo">
              <div>
                <div className="label">Verkefnið</div>
                <RichTextRender
                  style={{ color: "#fff" }}
                  className="richTextContent description"
                  content={data.description.json}
                ></RichTextRender>
              </div>

              {/* <div>
                <div className="label">Markmiðið</div>
                <RichTextRender
                  style={{ color: "#fff" }}
                  className="richTextContent description"
                  content={data.goal.json}
                ></RichTextRender>
              </div> */}
            </div>
          </div>

          <div id="videos">
            {data.vimeoVideos?.map((videoUrl, key) => {
              return (
                <ReactPlayer
                  key={key}
                  url={videoUrl}
                  height={window.innerWidth * 0.5 + "px"}
                  width="100%"
                  controls
                  style={{
                    minWidth: 320,
                    margin: "auto",
                    padding: 20,
                  }}
                />
              );
            })}
          </div>

          <div id="mobileImages">
            {data.mobileImagesCollection.items.map((image, key) => {
              return (
                <img src={formatImage(image.url, 1200)} key={key} alt="" />
              );
            })}
          </div>

          <div id="heroImages">
            {data.heroImagesCollection.items.map((image, key) => {
              return (
                <img
                  style={{ width: "100%" }}
                  src={formatImage(image.url, 1400)}
                  key={key}
                  alt=""
                />
              );
            })}
          </div>
          {nextCase && (
            <div className="infoSection">
              <div id="dontStop">
                <div>
                  Ekki stoppa hér,{" "}
                  <span id="nextJob">
                    kíktu á{" "}
                    <Link
                      to={`/verk/${nextCase.sys.id}`}
                      className="nextJobName"
                    >
                      {nextCase.customerName.toUpperCase()}
                    </Link>
                  </span>
                </div>

                <div id="sectionParent">
                  <div>
                    <img src={nextCase.thumbnail.url + "?w=700"} alt="" />
                  </div>
                  <div id="nextJobDesc">
                    <div id="nextJobTitle">{nextCase.title}</div>

                    <RichTextRender
                      style={{ color: "#fff" }}
                      className="richTextContent description"
                      content={nextCase.description.json}
                    ></RichTextRender>

                    <Link
                      to={`/verk/${nextCase.sys.id}`}
                      className="seeNextLink"
                    >
                      SJÁ VERKEFNI
                    </Link>
                    <Link to={`/verkin/`} className="seeNextLink">
                      SJÁ ÖLL VERKEFNI
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
