import Axios from "axios";

const spaceId = "b5otehmyi9gl";
const accessToken = "z_tkJTijR5q1kqhDwmy4DppZrXa3jhpHqj0Zw71Lnvs";

const getContentCategory = (category) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/?content_type=${category}&access_token=${accessToken}`
  );

export const getSingleEntry = (entryId) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/${entryId}?access_token=${accessToken}`
  );

const _getSingleAsset = (assetId) => {
  return Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/assets/${assetId}?access_token=${accessToken}`
  );
};

export const getSingleAsset = _getSingleAsset;

export const getImage = (assetId, width) => {
  return _getSingleAsset(assetId).then((res) => {
    const file = res.data.fields.file;
    return file.url.replace("downloads", "images") + "?w=" + width;
  });
};

export default getContentCategory;
