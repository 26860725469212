//@ts-nocheck
/* export const getFieldMedia = (sys: object, parent: object) => {
  
  // @ts-ignore
  const field = parent.includes.Asset.find((asset) => asset.sys.id === sys.id);
  return field.fields.file;
};


 */

export const getFieldMedia = (key: string, parent: object) => {
  const sysParent = parent.items.find((item) => item.fields[key].sys);
  const sys = sysParent.fields[key].sys;
  const field = parent.includes.Asset.find((asset) => asset.sys.id === sys.id);

  return field.fields.file;
};

export const getFieldMediaById = (id: string, parent: object) => {
  const field = parent.includes.Asset.find((asset) => asset.sys.id === id);

  return field.fields.file;
};
