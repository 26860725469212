//@ts-nocheck
import { ReactElement, useEffect, useState } from "react";
import HeroSection from "../../components/HeroSection";
import RichTextRender from "../../components/RichTextRender/RichTextRender";
import "./CaseStudy.scss";

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/b5otehmyi9gl/environments/master?access_token=z_tkJTijR5q1kqhDwmy4DppZrXa3jhpHqj0Zw71Lnvs",
  cache: new InMemoryCache(),
});

interface Props {}

export default function CaseStudy({}: Props): ReactElement {
  const [data, setData] = useState();

  useEffect(() => {
    const pathname = location.pathname;
    const entryId = pathname.replaceAll("/casestudy/", "");

    client
      .query({
        query: gql`
          query {
            case(id: "${entryId}") {
              topSectionBackground{
                url
              }
              fullpageImage{
                url
              }
              logo{
                url
              }
              kiwiBullets{
                json
              }
              method{
                json
              }
              boxesCollection{
                items{
                  title
                  
                  mobileImagesCollection{
                    items{
                      url
                    }
                  }
                  fullpageImage{
                    url
                  }
                  loweLeftImage{
                    url
                  }
                  title
                  description{
                    json
                  }
                }
              }
              
            }
          }
        `,
      })
      .then((res) => {
        setData(res.data.case);
      });
  }, []);

  const formatImage = (url, width) => {
    return url.replace("downloads", "images") + `?w=${width}`;
  };

  return data ? (
    <div id="caseStudy">
      <HeroSection
        vheight={100}
        className="caseHeader"
        overlayStyle={{
          backgroundImage: `url(${formatImage(
            data.topSectionBackground.url,
            1400
          )})`,
        }}
      >
        <img
          src={formatImage(data.logo.url, 400)}
          alt="logo"
          className="logo"
        />

        <h1 className="caseStudyTitle">CASE STUDY</h1>
        <div className="shortIntro">
          Body Shop þurfti aðstoð við að auka vefsölu en fyrst og fremst aðstoða
          þau með stafræna markaðsstarfið þeirra.
        </div>
      </HeroSection>

      <HeroSection
        vheight={70}
        innerContainerStyle={{
          backgroundImage: `url(${formatImage(data.fullpageImage.url, 1400)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></HeroSection>

      <HeroSection innerContainerStyle={{ background: "black" }}>
        <div className="infoSection">
          {data && (
            <>
              <div className="leftInfo">
                <div>
                  <div className="jobName">Aðferðin</div>
                </div>

                <div className="kiwiBullets">
                  <div className="label">Aðkoma Kiwi</div>
                  <RichTextRender
                    style={{ color: "#fff" }}
                    className="richTextContent bulletContent"
                    content={data.kiwiBullets.json}
                  ></RichTextRender>
                </div>
              </div>
              <div className="rightInfo">
                <div>
                  <div className="label">Verkefnið</div>
                  <RichTextRender
                    style={{ color: "#fff" }}
                    className="richTextContent description"
                    content={data.method.json}
                  ></RichTextRender>
                </div>
              </div>
            </>
          )}
        </div>
      </HeroSection>

      {data.boxesCollection.items.map((box, index) => {
        return (
          <HeroSection key={index} className="graySection">
            <div className="infoSection">
              {data && (
                <>
                  <div className="leftInfo">
                    <div className="boxTitle">{box.title}</div>

                    {box.loweLeftImage && (
                      <div className="lowerLeftImage">
                        <img src={box.loweLeftImage.url} alt="" />
                      </div>
                    )}
                  </div>
                  <div className="rightInfo">
                    {box.description && (
                      <RichTextRender
                        style={{ color: "#fff" }}
                        className="richTextContent boxDescription"
                        content={box.description.json}
                      ></RichTextRender>
                    )}
                  </div>
                </>
              )}
            </div>

            {!!box.mobileImagesCollection.items.length && (
              <div className="mobileImages">
                {box.mobileImagesCollection.items.map((img, index) => {
                  return (
                    <div key={index}>
                      <img src={formatImage(img.url, 700)} key={index} />
                    </div>
                  );
                })}
              </div>
            )}

            {box.fullpageImage?.url && (
              <div
                className="fullpageImage"
                style={{
                  backgroundImage: `url(${formatImage(
                    box.fullpageImage.url,
                    1400
                  )})`,
                }}
              ></div>
            )}
          </HeroSection>
        );
      })}
    </div>
  ) : (
    <></>
  );
}
